<template>
  <div>
    <h1 class=" my-3 px-3 p-sm-0 py-0 py-md-3 page-heading w-100">AnyTask™ Platform Terms and Conditions of use (updated Feb 2025)</h1>
    <div id="terms" class="container bg-white pt-2 px-4 text-left pb-3">
      <p>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS PLATFORM</p>
      <p>Please note that the cryptoasset services provided by the AnyTask™ Platform are outside the scope of the jurisdiction and protection of the Financial Ombudsman Service and the Financial Services Compensation Scheme.</p>
      <p><strong>INDEX</strong></p>
      <ol class="mb-3">
        <li><a href="#whatTerms">What’s in these terms?</a></li>
        <li><a href="#acceptance">Acceptance of terms</a></li>
        <li><a href="#otherTerms">There are other terms that may apply to you</a></li>
        <li><a href="#changesToTerms">We may make changes to these terms</a></li>
        <li><a href="#changesToPlatform">We may make changes to the AnyTask™ Platform</a></li>
        <li><a href="#withdrawSite">We may suspend or withdraw our site</a></li>
        <li><a href="#restrictions">User restrictions</a></li>
        <li><a href="#accountDetails">You must keep your account details safe</a></li>
        <li><a href="#material">How you may use material on our site</a></li>
        <li><a href="#profileGeneration">Profile generation</a></li>
        <li><a href="#informationOnSite">Do not rely on information on this site</a></li>
        <li><a href="#websiteLinks">We are not responsible for websites we link to</a></li>
        <li><a href="#UserGeneratedContent">User-generated content is not approved by us</a></li>
        <li><a href="#lossOrDamage">Our responsibility for loss or damage suffered by you</a></li>
        <li><a href="#uploadingContent">Uploading content to the AnyTask™ Platform</a></li>
        <li><a href="#prohibitedUses">Prohibited uses</a></li>
        <li><a href="#interactiveServices">Interactive services</a></li>
        <li><a href="#contentStandards">Content standards</a></li>
        <li><a href="#materialRights">Rights you are giving us to the material you upload</a></li>
        <li><a href="#viruses">We are not responsible for viruses and you must not introduce them</a></li>
        <li><a href="#breachOfTerms">Breach of these terms of use</a></li>
        <li><a href="#rulesForLinkingSites">Rules about linking to our site</a></li>
        <li><a href="#countryLaws">Which country’s laws apply to any disputes?</a></li>
        <li><a href="#tradeMark">Our trade marks are registered</a></li>
        <li><a href="#contactUs">Who we are and how to contact us</a></li>
      </ol>

      <ol class="mb-5 pb-5">
        <li class="anchor">
          <a class="anchorInline" id="whatTerms"/>
          <strong>WHAT'S IN THESE TERMS?</strong>
          <ol>
            <li>These terms tell you the rules for using our website <a href="https://anytask.com" target="_blank">https://anytask.com</a> (the AnyTask™ Platform) and the AnyTask App.</li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="acceptance"/>
          <b>BY USING THE ANYTASK™ PLATFORM YOU ACCEPT THESE TERMS</b>
          <ol>
            <li>By using the AnyTask™ Platform, you confirm that you accept these terms of use (as updated from time to time) and our privacy policy and any other terms referred to in these
              terms and incorporated by reference and that you agree to comply with them.
            </li>
            <li>If you do not agree to these terms, you must not use the AnyTask™ Platform.</li>
            <li>We recommend that you print a copy of these terms for future reference.</li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="otherTerms"/>
          <strong>THERE ARE OTHER TERMS THAT MAY APPLY TO YOU</strong>
          <ol>
            <li>These terms of use refer to our Privacy and Cookies Policy, which also applies to your use of
              the AnyTask™ Platform <a href="https://anytask.com/privacy-policy" target="_blank">https://anytask.com/privacy-policy</a>. This policy sets out the terms on which we process any personal data we collect from you, or that you provide to us. It also sets out information about
              the cookies on the AnyTask™ Platform. By using the AnyTask™ Platform, you consent to such processing and you warrant that all data provided by you is accurate.
            </li>
            <li>Sellers, where you use the Electroneum Ltd cryptocurrency (“ETN”) via the Services and the Digital Content (being the ETN Wallet, ETN App and any other services set out therein from time to time) the ETN Terms and Conditions for the Provision of Services and the Digital Content for the Use of ETN Tokens, those <a href="https://electroneum.com/terms-and-conditions/" target="_blank">https://electroneum.com/terms-and-conditions/</a> will also apply to you.</li>
            <li>Certain ETN services that you may wish to access will require you to have completed the Tier 3 due diligence, source of funds and “know your client” checks. Please
              see the ETN Terms and Conditions for the Provision of Services and the Digital Content for the Use of ETN Tokens for further information.
            </li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="changesToTerms"/>
          <strong>WE MAY MAKE CHANGES TO THESE TERMS</strong>
          <ol>
            <li>We may amend these terms from time to time. Every time you wish to use the AnyTask™ Platform, please check these terms to ensure you understand the terms that apply at that time. These terms were most recently updated in March 2023. Every time you use the AnyTask™ Platform, these terms, as set out in clause 2, will apply.</li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="changesToPlatform"/>
          <strong>WE MAY MAKE CHANGES TO THE ANYTASK™ PLATFORM</strong>
          <ol>
            <li>We may update and change the AnyTask™ Platform from time to time to reflect changes to the Services and the Digital Content, our users' needs and our business priorities. We
              will try to give you reasonable notice of any major changes.
            </li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="withdrawSite"/>
          <strong>WE MAY SUSPEND OR WITHDRAW THE ANYTASK™ PLATFORM</strong>
          <ol>
            <li>The AnyTask™ Platform is currently made available free of charge to Sellers, Buyers are charged a platform usage fee for purchasing and/or advertising Tasks via the AnyTask™ Platform, which
              is a percentage of the value of the Task they are purchasing and/or advertising.
            </li>
            <li>We do not guarantee that the AnyTask™ Platform, or any functionality or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or
              restrict the availability of all or any part of the AnyTask™ Platform for business or operational reasons. We will try to give you reasonable notice of any suspension or
              withdrawal.
            </li>
            <li>You are also responsible for ensuring that all persons who access the AnyTask™ Platform through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.</li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="restrictions"/>
          <b>USER RESTRICTIONS</b>
          <ol>
            <li>The AnyTask™ Platform is provided for the purposes of enabling Buyers and Sellers to contract directly with each other by easily finding and accessing Tasks (namely products and services);
              provided by freelance workers (Sellers, who are willing to accept payment in ETN), by users wishing to engage Sellers to carry out those Tasks (Buyers) and for such Sellers and Buyers it is directed at people
              aged 14 and older only. Please note that if the minimum age limit relating to any use of or access to our website or the AnyTask™ Platform is lower for your jurisdiction, 14 years
              is the minimum age acceptable by us in any such jurisdiction. Where that minimum age limit in your jurisdiction is greater than 14, you must comply with that greater
              age limit and we may withdraw or limit access to or use of our website or the AnyTask™ Platform pursuant to any law or regulation in your territory.
            </li>
            <li>We do not warrant, represent or guarantee that your access to or use of the AnyTask™ Platform or the content available on or through the AnyTask™ Platform is appropriate for use or available in your territory.</li>
            <li>Sellers are responsible for ascertaining and monitoring the legal status and requirements of the use of cryptocurrencies and distributed ledger technology (“blockchain”) in their territory and so that we can promote (at our discretion) this willingness of Sellers to be paid in ETN to a wider market.</li>
            <li>Buyers and Sellers are only permitted to engage with each other in respect of Tasks, through the AnyTask™ Platform. You must not provide or request (or attempt to provide or request) any contact details to or from other users of the AnyTask™ Platform.</li>
            <li>If from time to time you act in the capacity of both a Buyer and a Seller, you are expressly prohibited from directly or indirectly purchasing your own Tasks, whether through your own account or the account of any connected party (as defined in section 993 of the Income Tax Act 2007 and which definition shall also include where we consider that you have acted in conjunction with any third party on other than an “arms-length” commercial basis).</li>
            <li>Buyers and Sellers must conduct themselves, towards each other and us and the AnyTask™ Platform, lawfully and in good faith at all times.</li>
            <li>We reserve the right, at all times, to remove any Seller or Buyer from the AnyTask™ Platform at our absolute discretion and suspend and/or cancel those related accounts, regardless of previous acceptance of your status or activities on the AnyTask™ Platform.</li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="accountDetails"/>
          <b>YOU MUST KEEP YOUR ACCOUNT DETAILS SAFE</b>
          <ol>
            <li>If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat
              such information as confidential. You must not disclose it to any third party.
            </li>
            <li>We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have
              failed to comply with any of the provisions of these terms of use.
            </li>
            <li>If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us via <a
              href="https://support.anytask.com" target="_blank">https://support.anytask.com</a>.
            </li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="material"/>
          <b>HOW YOU MAY USE MATERIAL ON THE ANYTASK™ PLATFORM</b>
          <ol>
            <li>We are the owner or the licensee of all intellectual property rights in the AnyTask™ Platform, and in the material published on it. Those works are protected by copyright
              laws and treaties around the world. All such rights are reserved.
            </li>
            <li>You may print off one copy, and may download extracts, of any page(s) from the AnyTask™ Platform for your personal use and you may draw the attention of others within your
              organisation to content posted on the AnyTask™ Platform.
            </li>
            <li>You must not modify the paper or digital copies of any materials you have printed off or accessed in any way, and you must not use any illustrations, photographs,
              video or audio sequences or any graphics separately from any accompanying text.
            </li>
            <li>Our status (and that of any identified contributors) as the authors of content on the AnyTask™ Platform must always be acknowledged.</li>
            <li>You must not use any part of the content on the AnyTask™ Platform including any process, method or commercial model for commercial purposes without obtaining a written
              licence to do so from us or our licensors.
            </li>
            <li>If you print off, copy or download any part of the AnyTask™ Platform in breach of these terms of use, your permission to use the AnyTask™ Platform or any part of it will immediately
              and automatically cease and you must, at our option, return or destroy any copies of the materials you have made.
            </li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="profileGeneration"/>
          <b>PROFILE GENERATION</b>
          <ol>
            <li>The AnyTask™ Platform contains tools enabling you to generate profiles for Sellers, Buyers and Tasks. By using these tools, you acknowledge the following information:
              <ol>
                <li>Any profiles (whether for Sellers, Buyers or Tasks) that you create and upload are true, accurate and complete and not misleading. Profiles must use correct grammar and spelling and not contain any offensive or abusive language or images and not infringe any intellectual property rights. Profiles which do not meet these requirements may be rejected by us at any time (and those related accounts suspended and/or cancelled at our discretion), regardless of previous acceptance;</li>
                <li>Seller and Buyer Profiles or AnyTask™ accounts must not be shared or traded between individuals and only one profile may be created for each individual user;
                  and
                </li>
                <li>You acknowledge that any profiles created and uploaded are accessed, used, stored, transferred or otherwise disclosed and managed entirely at your own risk;
                </li>
                <li>Any users found creating multiple accounts will have all accounts suspended and/or cancelled.</li>
              </ol>
            </li>
            <li>
              The profile creation facility is made available to you:
              <ol>
                <li>“As is” (without modification as a standard offering which is not bespoke to your requirements);</li>
                <li>“As available” from time to time: we do not guarantee the continuity or availability of these services, which may be suspended or withdrawn in whole or in part
                  at its absolute discretion;
                </li>
                <li>Presently without charge to Sellers, however we reserve the right to apply charges in respect of future use and/or access; and</li>
                <li>Strictly on the basis that your use of these services is at your risk and that you agree to hold us harmless from any liability in relation to your use of
                  these services, for example: (a) if your profile is rendered inaccessible as a result of your non-compliance with these terms and conditions as a result of which we suspend,
                  limit or withdraw your access to these services; or (b) if there is an issue with your profile which is outside of our control; or (c) if your access to your profile is
                  suspended or delayed for any regulatory or other reason.
                </li>
              </ol>
            </li>
            <li>
              We will not be responsible for any loss or damage suffered by you for any errors in your profile made by you or any third party, or non-availability of your profile.
            </li>
            <li>
              We reserve the right, at all times, to remove any profile at our absolute discretion.
            </li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="informationOnSite"/>
          <b>DO NOT RELY ON INFORMATION ON THIS SITE</b>
          <ol>
            <li>The content found on this site is provided for general information only, we have not independently verified any of the profiles. It is not intended to amount to
              advice on which you should rely, nor should you consider that we endorse any of the information contained in the profiles.
            </li>
            <li>You must obtain your own professional or specialist advice before taking, or refraining from taking, any action on the basis of the content on this site.</li>
            <li>Although we make reasonable efforts to update the information on this site for which we are directly responsible (excluding profiles), we make no representations,
              warranties or guarantees, whether express or implied, that the content on this site including profiles is accurate, complete or up to date.
            </li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="websiteLinks"/>
          <b>WE ARE NOT RESPONSIBLE FOR WEBSITES WE LINK TO</b>
          <ol>
            <li>Other than links in respect of the use and download of the Digital Content, where this site contains links to other sites and resources provided by third parties,
              those links are provided for your information only and should not be interpreted as approval by us of those linked websites or information you may obtain from them.
            </li>
            <li>We have no control over the contents of those sites or resources.</li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="UserGeneratedContent"/>
          <b>USER-GENERATED CONTENT IS NOT APPROVED BY US</b>
          <ol>
            <li>This website may include information, profiles and materials uploaded by other users of the AnyTask™ Platform (Buyers and Sellers). This information and these profiles and
              materials have not been verified or approved by us. The views expressed by other users on the AnyTask™ Platform do not necessarily represent our views or values.
            </li>
            <li>Where inappropriate or poor quality content is found, it will be removed and we may take such other action in our discretion as we deem appropriate, whether in accordance with these terms, and/or as required by law or otherwise.</li>
            <li>If you wish to make representations about information and materials uploaded by other users please contact us at <a href="https://support.anytask.com" target="_blank">https://support.anytask.com</a>.
            </li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="lossOrDamage"/>
          <b>OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU</b>
          <ol>
            <li>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our
              negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.
            </li>
            <li>Different limitations and exclusions of liability will apply to any liability arising as a result of the provision of any Task to you, which are set out in the
             <a href="https://anytask.com/terms-and-conditions" target="_blank">TERMS AND CONDITIONS FOR THE PROVISION OF TASK MATCHING SERVICES AND DIGITAL CONTENT</a>.
            </li>
            <li>Please note that we only provide the AnyTask™ Platform as a convenient way of enabling Buyers to contract directly with Sellers and fulfil Tasks that they require from
              Sellers and that it is a condition of your usage (whether you are a Buyer or a Seller) that we have no liability to you, whether for any loss of profit, loss of
              business, business interruption, or loss of business opportunity or otherwise.
            </li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="uploadingContent"/>
          <b>UPLOADING CONTENT TO THE ANYTASK™ PLATFORM</b>
          <ol>
            <li>Whenever you make use of a feature that allows you to upload content to the AnyTask™ Platform, or to make contact with other users of the AnyTask™ Platform, you must comply with the
              content standards set out in these terms of use.
            </li>
            <li>You confirm that any such contribution does comply with those standards, and you will be liable to us and indemnify us for any breach of that condition. This means
              you will be responsible for any loss or damage we suffer as a result of your breach of that condition.
            </li>
            <li>Any content you upload to the AnyTask™ Platform will be considered non-confidential and non-proprietary. You retain all of your ownership rights in your content, but you are
              required to grant us a licence to use, store and copy that content and to distribute and make it available to third parties. The rights you license to us are
              described in these terms of use.
            </li>
            <li>We also have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to the AnyTask™ Platform constitutes a
              violation of their intellectual property or other rights, or of their right to privacy.
            </li>
            <li>We have the right to remove any posting you make on the AnyTask™ Platform if, in our opinion, your post does not comply with the content standards set out in these terms of
              use.
            </li>
            <li>You are solely responsible for securing and backing up your content.</li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="prohibitedUses"/>
          <b>PROHIBITED USES</b>
          <ol>
            <li>
              You may use the AnyTask™ Platform only for lawful purposes. You may not use the AnyTask™ Platform:
              <ol>
                <li>In any way that breaches any applicable local, national or international law or regulation.</li>
                <li>In any way that is: (a) unlawful or fraudulent, or has any unlawful or fraudulent intent, purpose or effect; or (b) in breach of clauses 7.5 and/or 7.6.</li>
                <li>For the purpose of harming or attempting to harm anyone in any way (which includes but is not limited to causing them any loss or attempting to cause them any loss).</li>
                <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).
                </li>
                <li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any
                  other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.
                </li>
                <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards set out below.</li>
                <li>To operate an employment service or jobs portal or competing freelancer or task matching or similar service.</li>
                <li>To transmit or attempt to transmit personal data which is reasonably capable of identifying any third party (except where a Task involves the provision of a
                  physical deliverable, in which case a Seller may only do so to the extent strictly necessary to enable the delivery of that deliverable to a Buyer). This
                  includes, without limitation, the name and age of you or any other user or third party.
                </li>
                <li>Otherwise than in accordance with generally accepted industry good practice.</li>
              </ol>
            </li>
            <li>
              You also agree:
              <ol>
                <li>Not to reproduce, duplicate, copy or re-sell any part of the AnyTask™ Platform (or content or functionality or reporting formats) in contravention of these terms of use</li>
                <li>Not to access without authority, interfere with, monitor, damage or disrupt:
                  <ol>
                    <li>any part of the AnyTask™ Platform;</li>
                    <li>any equipment or network on which the AnyTask™ Platform is stored or transmitted;</li>
                    <li>any software used in the provision of the AnyTask™ Platform;</li>
                    <li>the Services or the Digital Content or the provision of any of them; or</li>
                    <li>any equipment or network or software owned or used by any third party.</li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="interactiveServices"/>
          <b>INTERACTIVE SERVICES</b>
          <ol>
            <li>We may from time to time provide interactive services on the AnyTask™ Platform (“interactive services”). Where we do provide any interactive service, we will provide
              clear information to you about the kind of service offered, if it is moderated and what form of moderation is used (including whether it is human or technical).
            </li>
            <li>We will do our best to assess any possible risks for users from third parties when they use any interactive service provided on the AnyTask™ Platform, and we will decide
              in each case whether it is appropriate to use moderation of the relevant service (including what kind of moderation to use) in the light of those risks. However,
              we are under no obligation to oversee, monitor or moderate any interactive service we provide on the AnyTask™ Platform, and we expressly exclude our liability for any loss
              or damage arising from the use of any interactive service by a user in contravention of our content standards, whether the service is moderated or not.
            </li>
            <li>Where we do moderate an interactive service, we will normally provide you with a means of contacting the moderator, should a concern or difficulty arise.</li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="contentStandards"/>
          <b>CONTENT STANDARDS</b>
          <ol>
            <li>These content standards (set out in this clause 18.1 – Content Standards) apply to any and all material and specifically including Buyer Profiles, Seller Profiles, Buyer Task Profiles, Seller Task Profiles,
              electronic communications (including via the AnyTask™ Platform messaging service) and Work which you contribute to and/or via the AnyTask™ Platform (collectively
              “Contribution”), and to any interactive services associated with it.
            </li>
            <li>The Content Standards must be complied with in spirit as well as to the letter. The standards apply to each part of any Contribution as well as to its whole.
            </li>
            <li>We will determine, in our sole discretion, whether a Contribution breaches the Content Standards. We reserve the right, at all times, to remove any Contribution at our absolute discretion, regardless of any previous acceptance.</li>
            <li>
              A Contribution must:
              <ol>
                <li>Be true, accurate (where it states facts) and complete and not misleading.</li>
                <li>Be genuinely held (where it states opinions).</li>
                <li>Comply with the law applicable in England (which is a jurisdiction we have chosen due to the global nature of our business) and in any country from which it is posted.</li>
                <li>Use correct grammar and spelling and not contain any offensive or abusive language or images and not infringe any intellectual property rights. Contributions which do not meet these requirements may be rejected by us at any time (and those related accounts may be suspended and/or cancelled at our discretion), regardless of previous acceptance.</li>
              </ol>
            </li>
            <li>
              A Contribution must NOT:
              <ol>
                <li>Disclose any personal data (excluding as strictly required in any Seller and/or Buyer Profile) or confidential information.</li>
                <li>Contain imagery or any other recordings of any individual whom we reasonably believe to be under the age of 18.</li>
                <li>Be rude to, abusive or defamatory of any person.</li>
                <li>Be obscene, pornographic, offensive, hateful or inflammatory.</li>
                <li>Promote sexually explicit material.</li>
                <li>Promote violence.</li>
                <li>Promote or require the preparation of academic works on behalf of Buyers.</li>
                <li>Promote the sending of spam.</li>
                <li>Promote the selling of regulated products or services.</li>
                <li>Promote the selling of products or services related to healthcare.</li>
                <li>Promote the selling of products or services required for military operations.</li>
                <li>Require products or services which are required for real-time and/or mission critical systems or operations.</li>
                <li>Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</li>
                <li>Infringe any patent, copyright, database right, design right or trade mark or any other intellectual property of any other person.</li>
                <li>Be likely to deceive any person or be fraudulent.</li>
                <li>Breach any legal duty owed to a third party, such as a contractual duty or a duty of confidence.</li>
                <li>Promote any illegal activity.</li>
                <li>Be in contempt of court.</li>
                <li>Be threatening, abuse or invade another's privacy, or cause annoyance, inconvenience or needless anxiety.</li>
                <li>Be likely to harass, upset, embarrass, alarm or annoy any other person.</li>
                <li>Impersonate any person, or misrepresent your identity or affiliation with any person.</li>
                <li>Give the impression that the Contribution emanates from us, if this is not the case.</li>
                <li>Advocate, promote, incite any party to commit, or assist any unlawful or criminal act such as (by way of example only and without limitation) copyright infringement or computer
                  misuse.
                </li>
                <li>Contain a statement which you know or believe, or have reasonable grounds for believing, that members of the public to whom the statement is, or is to be,
                  published are likely to understand as a direct or indirect encouragement or other inducement to the commission, preparation or instigation of acts of
                  terrorism.
                </li>
                <li>Be contrary to: our reputation; relationships with our commercial partners; or our commercial interests generally.</li>
                <li>Attempt to vary or otherwise circumvent these terms and conditions.</li>
                <li>Contain any advertising or promote any services or web links to other sites.</li>
              </ol>
            </li>
            <li>Each Buyer Task Profile or Seller Task Profile shall only relate to a single discrete Task and not a number of tasks or a series or sequence of other Tasks.
            </li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="materialRights"/>
          <b>RIGHTS YOU ARE GIVING US TO USE MATERIAL YOU UPLOAD</b>
          <ol>
            <li>When you upload or post content to the AnyTask™ Platform (including but not limited to profiles for Sellers, Buyers and Tasks),
              you grant us a perpetual, worldwide, non-exclusive, royalty-free, transferable, sub-licensable licence to use,
              reproduce, distribute, prepare extracts and/or derivative works of, display, and perform that user-generated content in connection with the AnyTask™ Platform and the Services or the Digital
              Content which includes promotion (at our discretion) on all social media accounts and websites, in each case for us and our commercial partners (in connection with their services) from time to time as we or
              they see fit.
            </li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="viruses"/>
          <b>WE ARE NOT RESPONSIBLE FOR VIRUSES AND YOU MUST NOT INTRODUCE THEM</b>
          <ol>
            <li>We do not guarantee that the AnyTask™ Platform will be secure or free from bugs or viruses.</li>
            <li>You are responsible for configuring your information technology, computer programmes and platform to access the AnyTask™ Platform. You should use your own virus
              protection software.
            </li>
            <li>You (whether Buyer or Seller) must not misuse the AnyTask™ Platform by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or
              technologically harmful or disruptive. You must not attempt to gain unauthorised access to the AnyTask™ Platform, the server on which the AnyTask™ Platform is stored or any server,
              computer or database connected to the AnyTask™ Platform. You must not attack the AnyTask™ Platform via a denial-of-service attack or a distributed denial-of service attack. By
              breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement
              authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use the AnyTask™ Platform will
              cease immediately.
            </li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="breachOfTerms"/>
          <b>BREACH OF THESE TERMS OF USE</b>
          <ol>
            <li>When we consider that a breach of these terms of use has occurred, we may take such action as we deem appropriate.</li>
            <li>
              Failure to comply with these terms of use may result in our taking all or any of the following actions:
              <ol>
                <li>Immediate, temporary or permanent withdrawal of your right to use the AnyTask™ Platform.</li>
                <li>Immediate, temporary or permanent removal of any Contribution uploaded by you to the AnyTask™ Platform.</li>
                <li>Issue of a warning to you.</li>
                <li>Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach.</li>
                <li>Further legal action against you.</li>
                <li>Disclosure of such information to law enforcement or regulatory authorities as we reasonably feel is necessary or as required by law.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="rulesForLinkingSites"/>
          <b>RULES ABOUT LINKING TO THIS SITE</b>
          <ol>
            <li>You may link to this site, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</li>
            <li>You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part unless expressly approved by us in writing.</li>
            <li>You must not establish a link to this site in any website that is not owned by you.</li>
            <li>This site must not be framed on any other site, nor may you create a link to any part of this site other than the home page.</li>
            <li>We reserve the right to withdraw linking permission without notice.</li>
            <li>The website in which you are linking must comply in all respects with the content standards set out in these terms of use.</li>
            <li>If you wish to link to or make any use of content on this site other than that set out above, please contact us via <a href="https://support.anytask.com" target="_blank">https://support.anytask.com</a>
            </li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="countryLaws"/>
          <b>WHICH COUNTRY'S LAWS APPLY TO ANY DISPUTES?</b>
          <ol>
            <li>If you are a consumer, please note that these terms of use, their subject matter and their formation, are governed by English law (which is a jurisdiction we have chosen due to the global nature of our business). You and we both agree that
              the courts of England will have exclusive jurisdiction.
            </li>
            <li>If you are a business, these terms of use, their subject matter and their formation (and any non-contractual disputes or claims) are governed by English law. We
              both agree to the exclusive jurisdiction of the courts of England.
            </li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="tradeMark"/>
          <b>OUR TRADE MARKS ARE REGISTERED</b>
          <ol>
            <li>“TASKSCHOOL” Community trademark number: EU018155111; “ANYTASK” Community trademark number: EU018155104 (together with others from time to time) are trade marks
              owned by or licensed to us. You are not permitted to use them without our approval, unless they are part of material you are using as permitted under these terms of
              use and any use must be in accordance with our corporate brand identity guidelines in force from time to time.
            </li>
            <li>Where “AnyTaskProtection”&#8482; is referred to, it is used as an unregistered trademark.</li>
          </ol>
        </li>
        <li class="anchor">
          <a class="anchorInline" id="contactUs"/>
          <b>WHO WE ARE AND HOW TO CONTACT US</b>
          <ol>
            <li>“We”, “we” “Us”, “us”, “Our”, “our” means AnyTask and AnyTask-CEP acting jointly and/or severally (as the context requires).</li>
            <li>BELGRAVIA TRADING SERVICES LTD a company registered in England under company number 11150341 with its registered office 51 The Stream Ditton, Aylesford,
              Kent ME20 6AG (“Belgravia”) and is the owner and licensor of the AnyTask™ Platform jointly to AnyTask and AnyTask-CEP.
            </li>
            <li><a href="https://anytask.com" target="_blank">https://anytask.com</a> is a site operated jointly by AnyTask and AnyTask-CEP. You can contact us via <a href="https://support.anytask.com" target="_blank">https://support.anytask.com</a>.</li>
            <li>ANYTASK LTD a company registered in England under company number 12074221 with its registered office 51 The Stream Ditton, Aylesford, Kent ME20 6AG
              (“AnyTask”) is a joint licensee and operator of the AnyTask™ Platform. It is also the Buyer’s payment agent.
            </li>
            <li>Genvey 1B N.V. a company registered in the Commercial Register of the Curaçao Chamber of Commerce & Industry under company number 156690 (trading as “AnyTask-CEP”) is a joint licensee and operator of the AnyTask™ Platform. It is also the Seller’s collection agent.
            </li>
            <li>STRIPE PAYMENTS UK LTD a company registered in England under company number 08480771 with its registered office 9th Floor, 107 Cheapside, London, EC2V 6DN
              (“Stripe”) is the current "Fiat Payment Services Provider" for AnyTask.
            </li>
          </ol>
        </li>
      </ol>

      <h4 class="text-center mb-3 mt-5 pt-5"><b>TERMS AND CONDITIONS FOR THE PROVISION OF TASK MATCHING SERVICES AND DIGITAL CONTENT</b></h4>
      <p>This agreement was written in English. To the extent that any translated version of this agreement conflicts with the English version, the English version prevails.</p>
      <p>Date of last revision: March 2023</p>

      <p class="font-weight-bold">Your attention is specifically drawn to clause 5.29 relating to when the Buyer pays for a Task in a cryptocurrency other than ETN</p>

      <ol>
        <li><b>INFORMATION ABOUT US AND HOW TO CONTACT US</b>
          <ol>
            <li><b>Who we are. We are</b>
              <ol>
                <li>“We”, “we”, “Us”, “us”, “Our”, “our” means AnyTask, AnyTask-CEP, AnyTask-BEA, AnyTask-BCA and AnyTask-BPA acting jointly and/or severally (as the context requires).</li>
                <li>BELGRAVIA TRADING SERVICES LTD a company registered in England under company number 11150341 with its registered office 51 The Stream Ditton,
                  Aylesford, Kent ME20 6AG (“Belgravia”). Belgravia is the ultimate owner and licensor of the intellectual property rights in the AnyTask™ Platform jointly to AnyTask and AnyTask-CEP.
                </li>
                <li>ANYTASK LTD a company registered in England under company number 12074221 with its registered office 51 The Stream Ditton, Aylesford, Kent ME20 6AG
                  (“AnyTask”) is the Buyer’s payment agent.
                </li>
                <li>
                  Genvey 1A N.V. a company registered in the Commercial Register of the Curaçao Chamber of Commerce & Industry under company number 156689 (trading as “AnyTask-BEA”) is the Buyer’s ETN escrow agent.
                </li>
                <li>Genvey 1B N.V. a company registered in the Commercial Register of the Curaçao Chamber of Commerce & Industry under company number 156690 (trading as “AnyTask-CEP”) is the Seller’s collection agent.
                </li>
                <li>STRIPE PAYMENTS UK LTD a company registered in England under company number 08480771 with its registered office 9th Floor, 107 Cheapside, London, EC2V
                  6DN (“Stripe”) is the current "Fiat Payment Services Provider" for Buyers.
                </li>
                <li>
                  Genvey 2A N.V. a company registered in the Commercial Register of the Curaçao Chamber of Commerce & Industry under company number 156770 (trading as “AnyTask-BPA”) is the Buyer’s ETN purchase agent.
                </li>
                <li>
                  Genvey 2B N.V. a company registered in the Commercial Register of the Curaçao Chamber of Commerce & Industry under company number 156771 (trading as “AnyTask-BCA”) is the Buyer’s cryptoasset agent.
                </li>
                <li>
                  Cryptocurrency Exchange Provider means the cryptocurrency exchange provider appointed by AnyTask-BCA from time to time to facilitate the acceptance of payments from Buyers (for Task fees and platform usage charges) in a variety of cryptocurrencies (other than ETN) and standardisation of such variety of cryptocurrencies (other than ETN) into ETN.
                </li>
              </ol>
            </li>
            <li>
              <strong>What is AnyTask?</strong> AnyTask is a Task matching platform provided for the purposes of enabling Buyers and Sellers to contract directly with each other and to
              easily match Buyers and Sellers to Tasks (which for clarity in these terms and conditions, is whereby Sellers and Buyers are able to search for each other’s User
              Generated Content and NOT by way of any intervention or positive action on our part to co-ordinate users requirements); allowing Buyers to pay for digital services
              provided by Sellers in various different (1) fiat currencies; and (2) cryptocurrencies (where this functionality has been activated); from time to time and allowing Sellers to be paid in ETN (the cryptocurrency created by Electroneum Ltd) and to promote (at our discretion) this willingness of Sellers to be paid in ETN to a wider market.
            </li>
            <li><b>How to contact us.</b> You can contact us via <a href="https://support.anytask.com" target="_blank">https://support.anytask.com</a>.</li>
            <li><b>How we may contact you.</b> If we have to contact you we will do so by telephone you at the telephone number you have provided to us or by writing to you at the
              email address you have provided to us.
            </li>
            <li><b>"Writing" includes emails.</b> When we use the words "writing" or "written" in these terms, this includes emails.</li>
            <li><b>References to clauses.</b> References to clauses are to clauses of these terms.</li>
            <li><b>Clause headings.</b> Clause headings do not affect the interpretation of these terms.</li>
          </ol>
        </li>
        <li>
          <b>THESE TERMS</b>
          <ol>
            <li><b>What these terms cover.</b> These are the terms and conditions on which we supply products or services to you (and cover services that we currently provide or services and functionality that we may provide in the future), whether these are services or any form of digital
              content.
            </li>
            <li><b>Why you should read them.</b> Please read these terms carefully before you use the AnyTask Products, Services or the Digital Content. These terms tell you who we are, how we
              will provide products and Services to you and how you access the Digital Content, how you and we may change or end the contract and other important information. If you think that there is a
              mistake in these terms or require any changes, please contact us to discuss.
            </li>
          </ol>
        </li>
        <li>
          <b>THE SERVICES AND THE DIGITAL CONTENT</b>
          <ol>
            <li>
              We provide or make available the following services and the digital content for facilitating the use of the Task matching service (which for clarity, is whereby Sellers
              and Buyers are able to search for each other’s User Generated Content and NOT by way of any intervention or positive action on our part to co-ordinate users
              requirements) to enable Sellers and Buyers to contract directly with each other:
              <ol>
                <li><b>“Sellers”</b> are individual users who are skilled, experienced and qualified to provide and perform
                  digital services and content and to be paid in ETN and to have their willingness to be paid in ETN promoted (at our discretion) to a wider market.</li>
                <li><b>“Seller Profiles”</b> are profiles in a standard format that Sellers can upload to the AnyTask™ Platform to showcase their skills, experience and qualifications in
                  the digital services sector;
                </li>
                <li><b>“Buyers”</b> are users who have a Task and who wish to engage Sellers to fulfil such requirement.</li>
                <li><b>“Tasks”</b> are discrete requirements for the short term provision of digital services and/or content;</li>
                <li><b>“Task Profiles”</b> are profiles regarding Tasks in a standard format that:
                  <ol>
                    <li>Buyers can (when such functionality is available) upload to the AnyTask™ Platform to showcase opportunities for Sellers in the digital services sector to provide and perform digital services and content;
                      or
                    </li>
                    <li>Sellers can upload to the AnyTask™ Platform to showcase opportunities for Buyers wishing to acquire digital services and content.</li>
                  </ol>
                </li>
                <li>
                  The AnyTask™ Platform together with the services provided to you and the content we provide to you through it.
                  <br>
                  Together referred to as the <b>“Services and the Digital Content”</b>.
                </li>
              </ol>
            </li>
            <li>The Services and the Digital Content are provided and/or made available to you (Buyers and Sellers):
              <ol>
                <li>“As is” (without modification as a standard offering which is not bespoke to your requirements);</li>
                <li>“As available” from time to time: we do not guarantee the continuity or availability of the Services or the Digital Content which may be suspended or withdrawn in
                  whole or in part at our absolute discretion;
                </li>
                <li>Presently without charge to Sellers, however we reserve the right to apply charges in respect of future use and/or access to any updated Services or the Digital
                  Content that we make available to Sellers from time to time; and
                </li>
                <li>On the basis that your use of the Services and the Digital Content is at your risk and that you agree to hold us harmless from any liability in relation to your use
                  of the Services and the Digital Content.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>BY USING THE SERVICES OR THE DIGITAL CONTENT YOU ACCEPT THESE TERMS</strong>
            <p><strong>
            By accessing the AnyTask™ Platform and using the Services or the Digital Content, you (both Sellers and Buyers) confirm that you accept these terms and that you agree to comply with
            them and that you engage AnyTask, AnyTask-CEP, AnyTask-BEA, AnyTask-BCA and AnyTask-BPA (jointly and/or severally (as the context requires)) to provide Services and/or make the Digital Content available to you. If you do not agree to these terms, you must not access
            the AnyTask™ Platform or use the Services or the Digital Content.
            </strong></p>
        </li>
        <li>
          <b>TASK MATCHING SERVICES OVERVIEW</b>
          <ol>
            <li>The AnyTask™ Platform is run by AnyTask and AnyTask-CEP and AnyTask-BEA, AnyTask-BCA and AnyTask-BPA provide Buyers with supporting services which facilitate their use of the AnyTask™ Platform.</li>
            <li>Buyers create and upload Task Profiles which are then visible to Sellers.</li>
            <li>Sellers create and upload Task Profiles which are then visible to Buyers.</li>
            <li>The matching service for clarity, is whereby Sellers and Buyers are able (when such functionality is available) to search for each other’s User Generated Content and NOT by way of any intervention or positive action on our part to co-ordinate users’ requirements.
            </li>
            <li>Buyers hereby appoint AnyTask as their irrevocable fiat currency payment agent for the purposes of:
              <ol>
                <li>paying platform usage charges in respect of the creation and posting/hosting of
                  Buyer Task Profiles on the AnyTask™ Platform payable to AnyTask and AnyTask-CEP; and </li>
                <li>paying platform usage charges payable to AnyTask and AnyTask-CEP in respect of Buyers successfully matching with Sellers to perform a Task; and</li>
                <li>contributing to managing the process of sending payments to Sellers in ETN in respect of successfully completed Tasks.</li>
              </ol>
            </li>
            <li>
              Buyers hereby appoint AnyTask-BEA as their irrevocable ETN escrow agent for the purposes of:
              <ol>
                <li>collecting the Task fees in ETN in respect of the relevant Task, which Task fees are ring-fenced and locked in the AnyTask-BEA ETN Wallet, pending the successful completion of such Task (and subsequent payment to AnyTask-CEP); and</li>
                <li>collecting platform usage charges in respect of the creation and posting/hosting of Buyer Task Profiles on the AnyTask™ Platform payable to AnyTask and AnyTask-CEP; and</li>
                <li>collecting platform usage charges payable to AnyTask and AnyTask-CEP in respect of Buyers successfully matching with Sellers to perform a Task; and </li>
                <li>contributing to managing the process of sending payments to Sellers (for Task fees) in ETN in respect of successfully completed Tasks.</li>
              </ol>
            </li>
            <li>
              Buyers hereby appoint AnyTask-BCA as their irrevocable cryptoasset agent for the purposes of:
              <ol>
                <li>contributing to managing the process for accepting payments from Buyers (for Task fees and platform usage charges) (whether directly or indirectly) in a variety of cryptocurrencies (other than ETN); and</li>
                <li>contributing to managing the process of sending payments to Sellers (for Task fees) in ETN in respect of successfully completed Tasks.</li>
              </ol>
            </li>
            <li>
              Buyers hereby appoint AnyTask-BPA as their irrevocable ETN purchase agent for the purposes of:
              <ol>
                <li>contributing to managing the process for accepting payments from Buyers (for Task fees and platform usage charges) (whether directly or indirectly) in a variety of cryptocurrencies (other than ETN); and</li>
                <li>contributing to managing the standardisation of a variety of cryptocurrencies (other than ETN) from AnyTask-BCA into ETN; and </li>
                <li>contributing to managing the process of sending payments to Sellers (for Task fees) in ETN in respect of successfully completed Tasks.</li>
              </ol>
            </li>
            <li>
              <strong>Notwithstanding that Sellers have appointed AnyTask-CEP as their collection agent and Buyers have appointed: (1) AnyTask as their irrevocable fiat currency payment agent; (2) AnyTask-BEA as their irrevocable ETN escrow agent; (3) AnyTask-BCA as their irrevocable cryptoasset agent; and (4)  AnyTask-BPA as their irrevocable ETN purchase agent; the contracts formed in respect of the performance of all Tasks are directly between Buyer and Seller.</strong>
            </li>
            <li>
              The contract for the creation, posting/hosting of a Buyer Task Profile is between the Buyer and AnyTask and AnyTask-CEP jointly.
            </li>
            <li>
              The contract for the creation, posting/hosting of a Seller Task Profile is between the Seller and AnyTask and AnyTask-CEP jointly.
            </li>
            <li>
              Belgravia is not a party to any contract for the creation, posting/hosting of any Task Profile, nor is it acting as agent for or on behalf of any party.
            </li>
            <li>
              The maximum value of a single Task is limited to a set value as indicated on the AnyTask™ Platform from time to time.
            </li>
            <p class="font-weight-bold text-left mt-4">[[1] Buyers paying for Tasks in fiat currency]</p>
            <li>
              When paying for Tasks in fiat currency, Buyers pay:
              <ol>
                <li>
                  the Task fees (which are displayed inclusive of value added tax) in respect of the relevant Task to be held by AnyTask, pending the successful completion of such Task (and subsequent payment to the Seller via its collection agent AnyTask-CEP); and
                </li>
                <li>
                  platform usage charges directly to AnyTask, on behalf of AnyTask and AnyTask-CEP jointly, in respect of successfully completed Tasks ; and
                </li>
                <li>
                  the platform usage charges directly to AnyTask, on behalf of AnyTask and AnyTask-CEP jointly, for the creation and posting/hosting of the Buyer Task Profile, (pending posting of the Buyer Task Profile);
                </li>
              </ol>
              <p>
                and each of which payments is payable in fiat currency, currently $USD, at the rates shown on the AnyTask™ Platform from time to time.
              </p>
            </li>
            <li>
              Sellers hereby appoint AnyTask-CEP as their irrevocable collection agent for the purposes of managing the collection of fiat currency payments from Buyers (via AnyTask) for successfully completing a Task and transmitting them to Sellers in ETN.
            </li>
            <p class="font-weight-bold text-left mt-4">[[2] Buyers paying for Tasks in ETN – using smart contracts]</p>
            <li>Where Buyers wish to pay for Tasks in ETN, and this functionality has been activated on the AnyTask™ Platform, these payments of ETN to Sellers in respect of successfully completed Tasks are managed entirely by the AnyTask™ Platform using Buyer’s and Seller’s ETN Wallets respectively and are not paid to AnyTask (as payment agent for the Buyer) or collected by AnyTask-CEP (as collection agent for the Seller).</li>

            <li>
              Where Buyers wish to pay for Tasks in ETN, and this functionality has been activated on the AnyTask™ Platform, Buyers use their ETN Wallet (which must be fully operational) as facilitated via the AnyTask™ Platform to pay:
              <ol>
                <li>the Task fees in ETN in respect of the relevant Task, which Task fees are ring-fenced and locked in the Buyer’s ETN Wallet, pending the successful completion of such Task (and subsequent payment directly to the Seller); and</li>
                <li>platform usage charges in ETN directly to AnyTask-CEP, on behalf of AnyTask and AnyTask-CEP jointly, in respect of successfully completed Tasks ; and</li>
                <li>platform usage charges in ETN directly to AnyTask-CEP, on behalf of AnyTask and AnyTask-CEP jointly, in respect of the creation and posting/hosting of the Buyer Task Profile;</li>
              </ol>
              and each of which payments is payable in ETN at the rates shown on the AnyTask™ Platform from time to time.
            </li>
            <p class="font-weight-bold text-left mt-4">[[3] Buyers paying for Tasks in ETN - using AnyTask-BEA as the Buyers Escrow Agent]</p>
            <li>
              Where Buyers wish to pay for Tasks in ETN, and this functionality has been activated on the AnyTask™ Platform, these payments of ETN to Sellers (for Task fees) in respect of successfully completed Tasks are managed entirely by the AnyTask™ Platform exclusively using AnyTask-BEA (which is the Buyer’s irrevocable ETN escrow agent) ETN Wallet and are not paid to AnyTask (as payment agent for the Buyer).
            </li>
            <li>
              Sellers hereby appoint AnyTask-CEP as their irrevocable collection agent for the purposes of managing the collection of ETN payments from Buyers (via AnyTask-BEA) (for Task fees) for successfully completing a Task and transmitting them to Sellers in ETN.
            </li>
            <li>
              Where Buyers wish to pay for Tasks in ETN, and this functionality has been activated on the AnyTask™ Platform, Buyers send ETN from their ETN Wallet (which must be fully operational) to the AnyTask-BEA ETN Wallet as facilitated via the AnyTask™ Platform to pay:
              <ol>
                <li>
                  the Task fees in ETN in respect of the relevant Task, which Task fees are ring-fenced and locked in the AnyTask-BEA ETN Wallet, pending the successful completion of such Task (and subsequent payment to AnyTask-CEP); and
                </li>
                <li>
                  platform usage charges in ETN directly to AnyTask-BEA, on behalf of AnyTask and AnyTask-CEP jointly, in respect of successfully completed Tasks ; and
                </li>
                <li>
                  platform usage charges in ETN directly to AnyTask-BEA, on behalf of AnyTask and AnyTask-CEP jointly, in respect of the creation and posting/hosting of the Buyer Task Profile;
                </li>
                <p>and each of which payments is payable in ETN at the rates shown on the AnyTask™ Platform from time to time.</p>
              </ol>
            </li>
            <li>
              Sellers hereby appoint AnyTask-CEP as their irrevocable collection agent for the purposes of managing the collection of fiat currency payments from Buyers (via AnyTask) for successfully completing a Task and transmitting them to Sellers in ETN.
            </li>

            <p class="font-weight-bold text-left mt-4">[[4] Buyers paying for Tasks in cryptocurrency (other than ETN) – using AnyTask-BCA as the Buyers Cryptoasset Agent]</p>
            <li>
              Where Buyers wish to pay for Tasks in a cryptocurrency (other than ETN but which is also accepted on the AnyTask™ Platform), and this functionality has been activated on the AnyTask™ Platform, these payments of such cryptocurrency by Buyers will be allocated into an appropriate existing AnyTask-BCA cryptocurrency wallet (either directly or following standardisation by the Cryptocurrency Exchange Provider from the original cryptocurrency paid by the Buyer into an appropriate cryptocurrency for which AnyTask-BCA holds a relevant cryptocurrency wallet).
            </li>
            <li>
              The rate of conversion from the Buyers original cryptocurrency to that cryptocurrency for which AnyTask-BCA has an appropriate account is fixed at the date and time at which the standardisation from one cryptocurrency to another is performed by the Cryptocurrency Exchange Provider.
            </li>
            <li>
              Notwithstanding payment by the Buyer to AnyTask-BCA in a cryptocurrency other than ETN, AnyTask-BCA shall account for the payment to AnyTask-BPA who shall forward the equivalent amount of ETN to AnyTask-BEA on behalf of the Buyer.
            </li>
            <li>
              The rate of conversion from the standard cryptocurrency held by and for which AnyTask-BCA has an appropriate account to ETN is fixed at the date and time at which the standardisation from one cryptocurrency to another is performed by AnyTask-BPA.
            </li>
            <li>
              Once such payments from Buyers have been standardised into ETN as described above, these payments of ETN to Sellers (for Task fees) in respect of successfully completed Tasks are managed entirely by the AnyTask™ Platform exclusively using AnyTask-BEA (which is the Buyer’s irrevocable ETN escrow agent) ETN Wallet and are not paid to AnyTask (as payment agent for the Buyer).
            </li>
            <li>
              Sellers hereby appoint AnyTask-CEP as their irrevocable collection agent for the purposes of managing the collection of ETN payments from Buyers (via AnyTask-BEA) (for Task fees) for successfully completing a Task and transmitting them to Sellers in ETN.
            </li>
            <li>
              Where Buyers wish to pay for Tasks in cryptocurrency other than ETN, and this functionality has been activated on the AnyTask™ Platform, ETN is sent to the AnyTask-BEA ETN Wallet as facilitated via the AnyTask™ Platform (as described in clauses 5.22 to 5.26 inclusive) to pay:
              <ol>
                <li>the Task fees in ETN in respect of the relevant Task, which Task fees are ring-fenced and locked in the AnyTask-BEA ETN Wallet, pending the successful completion of such Task (and subsequent payment to AnyTask-CEP); and </li>
                <li>platform usage charges in ETN directly to AnyTask-BEA, on behalf of AnyTask and AnyTask-CEP jointly, in respect of successfully completed Tasks ; and </li>
                <li>platform usage charges in ETN directly to AnyTask-BEA, on behalf of AnyTask and AnyTask-CEP jointly, in respect of the creation and posting/hosting of the Buyer Task Profile;</li>
              </ol>
              and each of which payments is payable in ETN at the rates shown on the AnyTask™ Platform from time to time.
            </li>
            <li><strong>Buyers acknowledge and irrevocably accept that in consideration of the services provided to them by the AnyTask™ Platform generally and specifically the convenience in being able to pay for Tasks in a cryptocurrency other than ETN and due to the complexities in calculating the conversion rates and their timing and the volatility of cryptocurrency markets, that in the event that a Task does not complete successfully, then any refund due shall only be: (1) available in ETN refund vouchers for use on the AnyTask™ Platform; and (2) for the number of ETN held by AnyTask-BEA on behalf of the Buyer for the specific Task which did not complete successfully.</strong></li>
            <li>Sellers tag their Seller Profile against a particular Task Profile and the Buyer chooses the Seller that they think is the best person for the Task.</li>
            <li>Buyers may review Seller Task Profiles against a particular Task posted by a Seller and choose the Seller to perform that Task.</li>
            <li>Once a Seller has been chosen by a Buyer and the Seller has confirmed that they can complete the Task, the Task is locked to that Seller and cannot then be changed
              or cancelled. The rate of conversion from fiat currency to ETN (if applicable) for the payment that will become due from the Buyer to the Seller (assuming successful completion of
              the Task) is fixed at the date and time at which the Task is locked to the Seller.
            </li>
            <li>A Task can be cancelled by a Seller at any time if it has not been chosen by a Buyer and currently at no cost to the Seller.</li>
            <li>A Task can be cancelled by a Buyer at any time if it has not chosen a Seller. The platform usage charge element payable to AnyTask and AnyTask-CEP jointly is a charge for
              hosting and displaying the Buyer Task Profile and/or matching Seller and Buyers and is non-refundable, but the balance of the Task fees will be refunded to the Buyer
              subject to any administration charges.
            </li>
            <li>The Seller will complete the required work and submit it to the Buyer via the AnyTask™ Platform. Sellers and Buyers must communicate regarding Tasks only using the
              AnyTask™ Platform messaging service and by no other means.
            </li>
            <li>Buyers and Sellers (when such functionality is available) are strongly encouraged to review each other’s performance and award ratings in line with our Best Practice Guidance.</li>
            <li>Once the Buyer is satisfied with the end result (as indicated through the AnyTask™ Platform):
              <ol>
                <li>where the Buyer has paid in fiat currency, AnyTask will account for the payment for the completed Task in fiat currency to AnyTask-CEP, who will make payment to the Seller in ETN; or </li>
                <li>where the Buyer has paid in ETN (using a smart contract), the funds in the Buyer’s ETN Wallet (which must be fully operational) are unlocked and the payment for the completed Task in ETN is sent directly to the Seller’s ETN Wallet (which must be fully operational); or</li>
                <li>where the Buyer has paid in ETN or other accepted cryptocurrency (using AnyTask-BEA as the Buyer’s Escrow Agent), the ETN funds in the AnyTask-BEA ETN Wallet are unlocked and the payment for the Task fees for the completed Task in ETN is sent to AnyTask-CEP (as the irrevocable collection agent for the Seller) managing the collection of ETN payments (for Task fees) from Buyers (via AnyTask-BEA) for successfully completing a Task</li>
              </ol>
            </li>
            <li>Belgravia, AnyTask, AnyTask-CEP and each of their group companies are all permitted to use all work submitted through the AnyTask™ Platform, whether directly or
              indirectly, without restriction, for sales and marketing and promotional activities, whether through online social media, websites or otherwise.
            </li>
          </ol>
        </li>
        <li>
          <b>SELLERS</b>
          <ol>
            <li>
              <b>Sellers:</b>
              <ol>
                <li>must be old enough to have an online ETN account in order to create an AnyTask account which must be linked to a single: (1) unique mobile telephone number;
                  and (2) verified email address;
                </li>
                <li>must have a ETN Wallet (which must be fully operational) (which will be linked to their AnyTask account through the same unique mobile telephone number) and successfully
                  completed relevant know-your-client and anti-money-laundering requirements and acknowledge that this is the only mechanism by which they can receive payment for
                  completed Tasks;
                </li>
                <li>payments to the Seller’s ETN Wallet may be suspended for security reasons or if the Seller has breached the AnyTask™ Platform terms and conditions
                  of use. Sellers acknowledge that payments being sent to the Seller’s ETN Wallet may be subject to delays due to processing payments either by the
                  Fiat Payment Services Provider, or the Cryptocurrency Exchange Provider and/or as a result of processing through any relevant blockchain;
                </li>
                <li>must not attempt to take, or take payments from Buyers for completed Tasks, outside of the AnyTask™ Platform, if you are requested to do this please report this to us via
                  <a href="https://support.anytask.com" target="_blank">https://support.anytask.com</a>;
                </li>
                <li>acknowledge that they are engaging in a direct contractual relationship with Buyers and that it is their responsibility to ensure that goods and/or services
                  which are time critical (as may be agreed from time to time between the Buyer and the Seller) are sent to the Buyer within those time requirements, failing which
                  there shall be no obligation on the Buyer to pay for such goods and/or services unless the Buyer downloads (or in the case of physical goods, accepts receipt)
                  regardless of the Seller’s failure to meet those time requirements. It is the Seller’s responsibility to ensure that goods and/or services are sent to the Buyer
                  in good time and we accept no responsibility for delays or failures caused by non-availability of the AnyTask™ Platform or disruptions to the internet generally in
                  relation to the Seller’s transmission of goods and/or services;
                </li>
                <li>acknowledge that a payment made to the Seller from AnyTask-CEP for Task fees shall be considered to be a payment made directly from the Buyer:
                  <ol>
                    <li>notwithstanding that where the Buyer has paid in fiat currency, the Buyer shall make a payment to AnyTask (through the Fiat Payment Services Provider) who shall account for the payment to AnyTask-CEP who shall forward ETN to the Seller); or </li>
                    <li>where the Buyer has paid in ETN (using a smart contract), the Buyer shall make a direct payment to the Seller’s ETN Wallet; or</li>
                    <li>where the Buyer has paid in ETN (using AnyTask-BEA as the Buyers Escrow Agent) the Buyer shall make a direct payment to AnyTask-BEA who shall make payment to AnyTask-CEP who shall forward ETN to the Seller; or</li>
                    <li>notwithstanding that where the Buyer has paid in cryptocurrency other than ETN, the Buyer shall make a payment to AnyTask-BCA (directly or through the Cryptocurrency Exchange Provider) who shall account for the payment to AnyTask-BPA who shall make a direct payment to AnyTask-BEA who shall make payment to AnyTask-CEP who shall forward ETN to the Seller.</li>
                  </ol>
                </li>
                <li>acknowledge and accept that the amount of ETN sent to the Seller shall, where the Buyer has paid in fiat currency only, be calculated at the relevant applicable rate in force on the date and time that the ETN was sent to the
                  Seller by AnyTask-CEP and not at any other rate;
                </li>
                <li>
                  acknowledge and accept that the amount of ETN sent to the Seller shall, where the Buyer has paid in cryptocurrency (other than ETN) only, be calculated at the relevant applicable rate in force on the date and time that the ETN was accounted for by AnyTask-BCA to AnyTask-BPA and not at any other rate;
                </li>
                <li>are responsible for paying all taxes due by them in respect of any earnings from the use of the AnyTask™ Platform. In the event of any claim being made against
                  us in respect of such sums, Sellers agree that we (which for the purposes of this clause 6.1.9 includes any of the AnyTask™ Platform companies (including their group companies)) may recover any sums
                  sought pursuant to any demand or order from an enforcement agent or regulatory authority (whether in England and Wales or otherwise) without further notice to
                  them;
                </li>
                <li>we have no responsibility for Buyers engaging with Sellers who are promoting Tasks outside of the AnyTask™ Platform;</li>
                <li>must complete all Tasks competently and diligently and lawfully and as specified in the Task profile (including but not limited to the timescales for delivery,
                  which shall not be of the essence unless expressly required in the Buyer’s Task Profile);
                </li>
                <li>grant Buyers a worldwide, perpetual, exclusive, transferable, sub-licensable, royalty free licence, to use, exploit, market, sell and distribute all work
                  performed by a Seller and successfully delivered to and accepted by a Buyer, as required in a Task profile (“Purpose”) (“Work”), including the right to create
                  derivative works from the Work or any part of it and to incorporate the whole or any part of the Work into any new work carried out by or on behalf of the Buyer
                  or at the Buyer’s direction for the Purpose and the Seller waives all moral rights in respect of the Work and any new work created from it;
                </li>
                <li>where such functionality is available, where required under a Seller Task Profile or Buyer Task Profile to provide physical goods to a Buyer, provide these subject to the Buyer’s confirmation that
                  such goods received meet the specification under the Seller Task Profile or Buyer Task Profile and no title shall pass to the Buyer in respect of such goods until
                  the Buyer has confirmed unconditional acceptance of the goods or until the expiry of 3 days from receipt of such goods (whichever is the sooner) and the Seller
                  shall be entitled to a lien over such goods until such acceptance;
                </li>
                <li>shall ensure that any physical goods shipped (where such functionality has been activated) are adequately insured and monitored for tracking and audit purposes;</li>
                <li>shall be directly liable to Buyers in respect of any Work provided to a Buyer which infringes any third party intellectual property rights and Sellers are
                  responsible for maintaining an appropriate insurance policy to cover any likely risks arising from the Work they perform in respect of any Task;
                </li>
                <li>warrant and represent to Buyers that any Work is original and has not been copied from any other source and that the Seller has all appropriate rights and/or
                  ownership to licence the Work to the Buyer as envisaged pursuant to these terms.
                </li>
              </ol>
            </li>
            <li>
              We operate a self-billing model on behalf of Sellers. Sellers agree to provide all necessary information and assistance to enable the AnyTask™ Platform to raise invoices in respect of
              the Work on their behalf and warrant (on a full indemnity basis) that such information will be complete, accurate and not misleading. The delivery and content of
              invoices are the sole responsibility of the Seller and the AnyTask™ Platform shall have no liability to any party with regard to self-billing except as set out in clause 21 below.
            </li>
            <li>Sellers shall keep any information sent to them by Buyers confidential and only use such information for the purposes of producing the Work.</li>
            <li>Sellers must only submit Work once a Task has been completed, through the AnyTask™ Platform.</li>
            <li>Sellers acknowledge and accept that AnyTask and AnyTask-CEP’s entire liability (whether joint or several) in all circumstances is strictly limited to the charges
              paid or payable to AnyTask and/or AnyTask-CEP from time to time by the Seller which is a platform usage charge for hosting and displaying the Seller Task Profile and matching
              Sellers and Buyers for Tasks.
            </li>
            <li>Sellers giving feedback about Buyers warrant and represent that such feedback will be honest, accurate, complete and not misleading.</li>
            <li>Sellers shall comply with all Seller Best Practice Guidance published on the AnyTask™ Platform from time to time and acknowledge that part of the service is to promote (at our discretion) their willingness to be paid in ETN to a wider market.</li>
          </ol>
        </li>
        <li>
          <b>BUYERS</b>
          <ol>
            <li>
              <b>Buyers:</b>
              <ol>
                <li>must either be at least 14 years old in order to create an AnyTask account which must be linked to a single unique mobile telephone number;</li>
                <li>acknowledge that they are engaging in a direct contractual relationship with Sellers and that it is their responsibility to ensure that any goods and/or
                  services which are required and which are time critical (as may be agreed from time to time between the Buyer and the Seller) are clearly communicated to the
                  Seller to enable the Seller to comply with those time requirements. In the event that the Seller fails to meet those time requirements but the Buyer downloads (or
                  in the case of physical goods where such functionality is activated, accepts receipt) regardless of the Seller’s failure to meet those time requirements, the Buyer acknowledges that it shall be liable
                  to make payment to the Seller in full. We accept no responsibility for delays or failures caused by non-availability of the AnyTask™ Platform or disruptions to the
                  internet or any relevant blockchain generally in relation to the Buyer’s receipt of goods and/or services;
                </li>
                <li>acknowledge that:
                  <ol>
                    <li>a fiat currency payment made from the Buyer to AnyTask (through the Fiat Payment Services Provider in respect of Task fees) shall be considered to be a payment
                      made directly to the Seller (notwithstanding that AnyTask shall account for the fiat payment to AnyTask-CEP who shall forward ETN to the Seller); and</li>
                    <li>where the Buyer has paid directly in ETN, the Buyer shall be deemed to make a direct payment to the Seller’s ETN Wallet; and</li>
                    <li>where the Buyer has paid in any cryptocurrency (other than ETN), the Buyer shall be deemed to make a direct payment of ETN to the Seller’s ETN Wallet. </li>
                  </ol>
                  Buyers acknowledge that payments being sent to the Seller may be subject to delays due to processing payments either by the Fiat Payment Services Provider, the Cryptocurrency Exchange Provider and/or as a result of processing through AnyTask and or the ETN or any other relevant blockchain;
                </li>
                <li>must only use a valid and authorised credit and/or debit card or official online ETN account to make payments via the AnyTask™ Platform and Buyers warrant and represent that they are authorised and
                  permitted to make such payments;
                </li>
                <li>must not attempt to make or make payments to Sellers for completed Tasks, outside of the AnyTask™ Platform, if you are requested to do this please report this to us via
                  <a href="https://support.anytask.com" target="_blank">https://support.anytask.com</a></li>
                <li>are responsible for paying all taxes (including but not limited to VAT, GST or any similar sales tax or taxes from time to time) due by them in respect of any
                  payments made through the use of the AnyTask™ Platform;
                </li>
                <li>we have no responsibility for Buyers engaging with Sellers who are promoting Tasks outside of the AnyTask™ Platform;</li>
                <li>shall co-operate with Sellers in a timely manner and in good faith and providing Sellers with all reasonable information and instructions to enable Sellers to
                  complete all Tasks competently and diligently and as specified in the Task profile (it is the Buyer’s responsibility to set out any timescales for delivery, which
                  shall not be of the essence unless expressly required in the Buyer’s Task Profile or specifically communicated to the Seller);
                </li>
                <li>acknowledge that only once they have received and accepted the Work (as defined in clause 6.1.12) are they granted a worldwide, perpetual, exclusive,
                  transferable, sub-licensable, royalty free licence by the Seller, to use, exploit, market, sell and distribute the Work, including the right to create derivative
                  works from the Work or any part of it and to incorporate the whole or any part of the Work into any new work carried out by or on behalf of the Buyer or at the
                  Buyer’s direction;
                </li>
                <li>acknowledge that in the event, following acceptance of any Work, if the Buyer makes any claim with its Fiat Payment Services provider (or credit card or debit card
                  provider) to reclaim or cancel any payment made, then in the absence of a bona-fide and legitimate dispute relating to infringement of intellectual property in
                  the Work, the Buyer shall automatically be deemed to have terminated the licence granted to it by the Seller (including in relation to any derivative works)
                  pursuant to clauses 6.1.12 and 7.1.9 pending resolution of the dispute. The platform usage charge element payable to AnyTask and AnyTask-CEP is a charge for hosting and
                  displaying the Buyer Task Profile and matching Sellers and Buyers in respect of Tasks and is non-refundable, but the balance of the Task fees reclaimed by the
                  Buyer, if upheld, following resolution of the dispute, will be refunded to the Buyer.
                </li>
                <li>acknowledge that:
                 <ol>
                  <li>if they download (or in the case of physical delivery, accept receipt) of the goods and/or services sent to them by a Seller AND do not raise
                  any query through the AnyTask™ Platform (that the goods and/or services do not meet the specifications set out in the Seller Task Profile or Buyer Task Profile) within 3
                  days of such download or receipt then this shall be deemed to be an unqualified acceptance of the goods and/or services; or</li>
                  <li>
                    if they fail to download (or in the case of physical delivery, fail to accept receipt) of the goods and/or services sent to them by a Seller AND do not raise any query through the AnyTask™ Platform (that the goods and/or services do not meet the specifications set out in the Seller Task Profile or Buyer Task Profile) within 28 days of such goods or services having been made available by the Seller, then this shall be deemed to be an unqualified acceptance of the goods and/or services;
                  </li>
                 </ol>
                </li>
                <li>shall ensure that in respect of any physical goods shipped, that they request that these are adequately insured and monitored for tracking and audit purposes by
                  the Seller and it is the Buyer’s responsibility to meet any costs relating to importation, insurance and any applicable customs duties, taxes, levies or similar
                  charges;
                </li>
                <li>acknowledge that Sellers are solely responsible in respect of any Work provided to a Buyer which infringes any third party intellectual property rights and must
                  satisfy themselves that Sellers have in place an appropriate insurance policy to cover any likely risks arising from the Work they perform in respect of any Task;
                </li>
                <li>acknowledge that additional terms and conditions and non-refundable charges of: (1) the Fiat Payment Services Provider; and (2) the Cryptocurrency Exchange Provider shall also apply to payments made by the Buyer for which the Buyer shall be primarily liable.</li>
              </ol>
            </li>
            <li>Once a Buyer Task Profile has been created and uploaded and paid for, it will be searchable on the AnyTask™ Platform and allocated a unique reference code.</li>
            <li>Buyers shall only send Sellers such information as is necessary for the purposes of producing the Work and shall be responsible for determining whether such
              information is confidential or not and whether or not it should be disclosed to the Seller.
            </li>
            <li>Buyers shall keep any information sent to them by Sellers confidential and only use such information for the purposes of receiving and/or using the Work as may be
              directed by the Seller.
            </li>
            <li>Buyers must only accept Work once a Task has been completed, through the AnyTask™ Platform.</li>
            <li>Buyers acknowledge and accept that the aggregate collective liability of AnyTask, AnyTask-CEP’s, AnyTask-BEA, AnyTask-BCA and AnyTask-BPA (whether joint or several) in all circumstances is strictly limited to the
              platform usage charge element paid or payable to AnyTask and/or AnyTask-CEP from time to time by the Buyer which is a charge for hosting and displaying the Buyer Task Profile
              and Buyers matching with Sellers for Tasks.
            </li>
            <li>Buyers giving feedback about Sellers warrant and represent that such feedback will be honest, accurate, complete and not misleading.</li>
            <li>Buyers shall comply with all Buyer Best Practice Guidance published on the AnyTask™ Platform from time to time.</li>
          </ol>
        </li>
        <li>
          <b>USER GENERATED CONTENT</b>
          <ol>
            <li>Buyer Profiles, Seller Profiles, Buyer Task Profiles, Seller Task Profiles and Work are all User Generated Content and must meet all <a href="#contentStandards">Content
              Standards</a> that we specify from time to time. User Generated Content is not actively moderated or approved of by us and its presence on the AnyTask™ Platform should not be
              deemed to be an endorsement or approval of it by us;
            </li>
            <li>Where User Generated Content does not meet all Content Standards, this may result in the removal of that User Generated Content and suspension and/or cancellation
              of Buyer’s or Seller’s AnyTask accounts.
            </li>
            <li>
              Additionally, in the event that we receive any complaint that any User Generated Content infringes the intellectual property rights of any third party, we reserve the
              right to:
              <ol>
                <li>suspend and/or cancel your (Buyer or Seller) AnyTask account or any listed Task;</li>
                <li>suspend any sums that you have paid or are due, in relation to your AnyTask account until such issue is resolved;</li>
                <li>disclose your details to any law enforcement or regulatory authority in your jurisdiction;</li>
                <li>disclose your email address to the third party complainant making such complaint so that the complainant may contact you directly.</li>
              </ol>
            </li>
            <li>Work must be capable of being sent and received electronically, there must not be any requirement for physical delivery (unless such functionality has been implemented, in which case additional terms shall apply) and Work must only be sent via the AnyTask™ Platform.
            </li>
            <li>Neither Belgravia, AnyTask, AnyTask-CEP, AnyTask-BEA, AnyTask-BCA, nor AnyTask-BPA accept or have any responsibility, obligation or liability in respect of any: User Generated Content; Tasks; or
              related Work.
            </li>
          </ol>
        </li>
        <li>
          <b>QUALITY CONTROL / DISPUTES</b>
          <ol>
            <li>In the event of a dispute between the Seller and the Buyer in relation to any Work submitted in respect of a completed Task, the parties, should acting in good
              faith, attempt to resolve matters between them.
            </li>
            <li>If the Buyer and Seller are unable to resolve any dispute between them in respect of any Work, then please report this to us via <a
              href="https://support.anytask.com" target="_blank">https://support.anytask.com</a>.
            </li>
            <li>We do not warrant, represent or guarantee that your use of the Services or the Digital Content is appropriate for use or available in your territory. You are
              responsible for ascertaining and monitoring the legal status of the Services and the Digital Content in your territory and you do this at your own risk.
            </li>
            <li>The AnyTask™ Platform will comply with all relevant laws in England (which is a jurisdiction we have chosen due to the global nature of our business).</li>
          </ol>
        </li>
        <li>
          <b>TAXATION</b>
          <ol>
            <li>We make no representation as to the tax status of your use of the Services or the Digital Content.</li>
            <li>You are solely responsible for determining your tax liability (including indirect taxation for online marketplaces) in relation to your use of the Services and
              the Digital Content and must take your own tax and other professional advice.
            </li>
          </ol>
        </li>
        <li>
          <b>INFORMATION PROVIDED BY US</b>
          <ol>
            <li>The information provided by us is provided for general information only. It is not intended to amount to advice on which you should rely.</li>
            <li>You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of any information provided by us.</li>
          </ol>
        </li>
        <li>
          <b>USER RESTRICTIONS</b>
          <br>
          The Services and the Digital Content are directed at users aged 14 or older only. Where we have reason to believe you are under the age of 14, we reserve the right to take
          any of the actions described in clause 23 below.
        </li>
        <li>
          <b>YOU MUST KEEP YOUR ACCOUNT DETAILS SAFE</b>
          <ol>
            <li>When you use your AnyTask account, you will be asked to provide a password. You must keep your password, and all other information provided as part of our security
              procedures safe and confidential. You must not disclose this information to any third party.
            </li>
            <li>You must use a unique password which you do not use for any other service, product or digital content.</li>
            <li>You are responsible for ensuring and maintaining the security of any device on which you use or access the Services or the Digital Content.</li>
            <li>If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at <a
              href="https://support.anytask.com" target="_blank">https://support.anytask.com</a>.
            </li>
          </ol>
        </li>
        <li>
          <b>DIGITAL CONTENT DOWNLOADS</b>
          <ol>
            <li>We make the Digital Content available for use and download from our website (<a href="https://anytask.com/" target="_blank">https://anytask.com/</a>) which link to
              the Google Play Store and Apple App Store (“<b>Approved Marketplaces</b>”). You must only use the Services or download the Digital Content via our website link to the
              Approved Marketplaces.
            </li>
            <li>All Digital Content is only made available through us on Approved Marketplaces. If you wish to download the Digital Content, you should do
              so only after following links contained on our official site. We do not endorse and are in no way affiliated with any other platforms or software which purport to
              provide access to, or integrate with, the Services or the Digital Content.
            </li>
            <li>Whilst we make reasonable efforts to get taken down from the Approved Marketplaces or elsewhere any malicious fake, scam or cloned mobile applications, software,
              websites or other content which purports to be affiliated with us, we shall be in no way liable for any loss or damage you may incur as a result of your use or
              download of such mobile applications.
            </li>
          </ol>
        </li>
        <li>
          <b>LICENCE</b>
          <br>
          In return for your agreeing to comply with these terms you may access and use any of the Digital Content on a non-exclusive, non-transferable basis in accordance with its licence terms.
        </li>
        <li>
          <b>LICENCE RESTRICTIONS</b>
          <br>
          The licence granted under clause 15 above is subject to the following restrictions additional to the licence terms:
          <ol>
            <li>Your use of the Digital Content is restricted to use in object code form only.</li>
            <li>You may make copies of the Digital Content for back-up purposes.</li>
            <li>Except as stated in clause 16.2 above or otherwise permitted by law in your jurisdiction, you have no right (and must not permit any third party) to copy, adapt,
              reverse engineer, decompile, disassemble, modify, adapt, run emulations of, or make error corrections to the Digital Content in whole or in part except to the extent
              that any reduction of the Digital Content to human readable form (whether by reverse engineering, decompilation or disassembly) is necessary for your lawful use of
              the Digital Content.
            </li>
          </ol>
        </li>
        <li>
          <b>NEW RELEASES AND UPDATES</b>
          <ol>
            <li>Free supplementary software code or updates of the Digital Content may be made available from time to time. If such updates are made available, you must install all such supplementary software code or
              updates promptly upon release.
            </li>
            <li>If supplementary software code or updates are made available to you in order to address security or other issues which we (at our absolute discretion deem critical),
              you must install such supplementary software code or updates. If you choose not to do so you may not be able to continue using the Services or the Digital Content and
              you acknowledge the security risks of using outdated Digital Content.
            </li>
          </ol>
        </li>
        <li>
          <b>SYSTEM REQUIREMENTS</b>
          <ol>
            <li>From time to time we may recommend minimum operating system or technical requirements (including but not limited to choice of browser) for accessing the AnyTask™ Platform
              and the Digital Content so that you can get the best from the Services. Please be sure to check for any best practice guidance that we may post on the site from time to time.
            </li>
          </ol>
        </li>
        <li>
          <b>OUR OBLIGATIONS TO YOU</b>
          <ol>
            <li>The Digital Content is provided to you on an “as is” basis. You accept responsibility for selection of the Digital Content to achieve your intended results and
              acknowledge that the Digital Content has not been developed to meet your individual requirements.
            </li>
            <li>Due to the nature of software, we cannot warrant that your use of the Digital Content will be uninterrupted or error-free. Whilst we take all reasonable precautions
              to ensure that the Services and the Digital Content are secure, we cannot guarantee that the Services and the Digital Content are immune from various forms of attack by
              malicious third parties.
            </li>
            <li>During periods of high demand users may experience transaction delays or (in a minority of circumstances) failures. You acknowledge that the Service or the Digital
              Content may be subject to limitations, delays and other problems inherent in the use of blockchain technology and internet communications facilities.
            </li>
            <li>We may limit or restrict your access to the Services or the Digital Content in order to perform maintenance, updates or improvements.</li>
            <li>To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to these terms, the Services or the Digital
              Content, whether express or implied.
            </li>
          </ol>
        </li>
        <li>
          <b>PROHIBITED USES</b>
          <ol>
            <li>
              You must use the Services and the Digital Content only for lawful purposes. You must not use the Services or the Digital Content:
              <ol>
                <li>In any way that breaches any applicable local, national or international law or regulation.</li>
                <li>In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.</li>
                <li>For the purpose of harming or attempting to harm anyone in any way.</li>
                <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).
                </li>
                <li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any
                  other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.
                </li>
                <li>Otherwise than in accordance with generally accepted freelance industry good practice.</li>
              </ol>
            </li>
            <li>
              You also agree not to access without authority, interfere with, damage or disrupt:
              <ol>
                <li>any equipment or network on which the Services or the Digital Content are stored or installed;</li>
                <li>any software used in the provision of the Services or the Digital Content; or</li>
                <li>any equipment or network or software owned or used by any third party.</li>
              </ol>
            </li>
            <li>Where your use of the Services and the Digital Content is unlawful we will report such activities to the relevant legal, regulatory or enforcement authorities. We will
              co-operate with those authorities during any subsequent investigation or prosecution.
            </li>
          </ol>
        </li>
        <li>
          <b>OUR LIABILITY TO YOU</b>
          <ol>
            <li>We make available Services and the Digital Content which facilitate the use of the Task matching service (which for clarity, is whereby Sellers and Buyers are able to search
              for each other’s User Generated Content and NOT by way of any intervention or positive action on our part to co-ordinate users requirements) and the marketing (at our discretion)
              and hosting/posting of Tasks; each whether through the AnyTask™ Platform or otherwise. We do not represent, investigate, or contract on behalf of users. As such, by entering into a transaction with
              another user via the Services or the Digital Content you agree that we are not responsible for and shall not be liable to you, or to any third party, in relation to a
              transaction (including but not limited to any enforcement of that transaction, or the enforceability or validity of any agreement between a Buyer and Seller), or for
              any acts or omissions of other users of the Services or the Digital Content. You must conduct your own due diligence and enquiries before entering into any transaction
              with another user.
            </li>
            <li>Nothing in these terms shall limit or exclude our liability for any matter in respect of which it would be unlawful for us to exclude or restrict liability,
              including death or personal injury caused by our negligence, or the negligence of our employees, agents or subcontractors (as applicable);and/or fraud or fraudulent
              misrepresentation.
            </li>
            <li>Except to the extent expressly stated in these terms, all terms implied by statute, common law or otherwise are excluded, including implied conditions, warranties
              or other terms as to satisfactory quality, fitness for purpose or the use of reasonable skill and care.
            </li>
            <li>Subject to clause 21.2: we shall not be liable to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, for any loss of
              profit, or any indirect or consequential loss arising under or in connection with your use of the Services or the Digital Content.
            </li>
            <li>Where there is any dispute between you and another user, we may (in our absolute discretion) provide such assistance to you as we consider to be reasonable in the
              circumstances, but we will not be responsible for resolving or mediating such dispute. Any such disputes must be taken up by you with the relevant user who is
              directly responsible to you for such matters.
            </li>
          </ol>
        </li>
        <li>
          <b>INTELLECTUAL PROPERTY RIGHTS</b>
          <ol>
            <li>In this clause, “<b>Intellectual Property Rights</b>” means all patents, utility models, rights to inventions, copyright and related rights, trade marks and service
              marks, trade names and domain names, rights in get-up, goodwill and the right to sue for passing off or unfair competition, rights in designs, rights in computer
              software, database rights, rights to preserve the confidentiality of information (including know-how and trade secrets) and any other intellectual property rights,
              including all applications for and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all similar or
              equivalent rights or forms of protection which subsist or will subsist, now or in the future, in any part of the world.
            </li>
            <li>You acknowledge that all Intellectual Property Rights in the Digital Content, supplementary software code or updates to it belong and shall belong to Belgravia, and you
              shall have no rights in or to the Digital Content other than the right to use it in accordance with these terms and the specific licence terms that relate to it.
            </li>
          </ol>
        </li>
        <li>
          <b>TERM AND TERMINATION</b>
          <ol>
            <li>These terms (as updated from time to time) apply for as long as you use the Services or the Digital Content.</li>
            <li>
              When we consider that a breach of these terms has occurred, we may take such action as we deem appropriate. Failure to comply with these terms of use may result in
              our taking all or any of the following actions:
              <ol>
                <li>Immediate, temporary or permanent withdrawal of your right to use the Services and the Digital Content (or any part of them).</li>
                <li>Issue of a warning to you.</li>
                <li>Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs)
                  resulting from the breach.
                </li>
                <li>Further legal action against you.</li>
                <li>Disclosure of such information to law enforcement, regulatory or other authorities as we reasonably feel is necessary or as required by law.</li>
              </ol>
            </li>
            <li>We may also take the actions outlined in clause 23.2 above:
              <ol>
                <li>pursuant to any ruling, order or instruction from any relevant legal, regulatory or other enforcement authority; or</li>
                <li>following any change in the laws or regulations in your territory which renders our supply of Services or the Digital Content to you unlawful.</li>
              </ol>
            </li>
            <li>
              On termination of your access to the Services and the Digital Content:
              <ol>
                <li>all rights granted to you under these terms shall cease;</li>
                <li>you must cease all activities authorised by these terms; and</li>
                <li>you must immediately destroy or return to us all copies of the Digital Content then in your possession, custody or control.</li>
              </ol>
            </li>
            <li>Any provision of these terms that expressly or by implication is intended to come into or continue in force on or after termination of your access to the Services
              or the Digital Content shall remain in full force and effect.
            </li>
          </ol>
        </li>
        <li>
          <b>OUR RIGHTS TO MAKE CHANGES</b>
          <br>
          We may make changes to these terms or to the Services or the Digital Content from time to time to reflect changes to our users’ needs and our business priorities. We will try
          to give you reasonable notice of any major changes. Where you continue to access the AnyTask™ Platform after changes have been implemented then we are entitled to treat you as
          having agreed to such changes.
        </li>
        <li>
          <b>HOW WE USE YOUR PERSONAL INFORMATION</b>
          <br>
          We will use the personal information you provide to us in accordance with our <a href="https://anytask.com/privacy-policy" target="_blank">Privacy and Cookies Policy</a>.
        </li>
        <li>
          <b>OTHER IMPORTANT TERMS</b>
          <ol>
            <li><b>We are not responsible for events outside our control.</b> If the provision of the Services or making the Digital Content available is delayed or prevented by an event outside our
              control then, if possible, we will take steps to minimise the effect of the delay. Provided we do this we will not be liable for delays caused by the event.
            </li>
            <li><b>We may transfer this agreement to someone else.</b> We may transfer our rights and obligations under these terms to another organisation. We will always use our
              reasonable endeavours to notify you of this in writing if this happens.
            </li>
            <li><b>You need our consent to transfer your rights to someone else.</b> You may not transfer your rights or your obligations under these terms to another person unless we
              specifically agree to this in writing.
            </li>
            <li><b>We may use trusted independent subcontractors to provide parts of the Services and the Digital Content.</b> However if we do so we will remain responsible for their acts
              and omissions at all times.
            </li>
            <li>We (AnyTask and/or AnyTask-CEP) are not your partners or agents.(other than where expressly specified on a limited basis and for a limited purpose only). Nothing in these terms is intended to, or shall be deemed to, establish any partnership or joint
              venture between us (AnyTask and/or AnyTask-CEP) and you, constitute either us or you as the agent of any other party (other than where expressly specified on a limited basis and for a limited purpose only), or authorise us or you to enter into any
              commitments for or on behalf of any other party.
            </li>
            <li><b>Nobody else has any rights under these terms.</b> This contract is between you and us. No other person shall have any rights to enforce any of its terms.</li>
            <li>If a court finds part of these terms illegal, the rest will continue in force. Each of the clauses of these terms operates separately. If any court or relevant
              authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.
            </li>
            <li><b>Even if we delay in enforcing these terms, we can still enforce them later.</b> If we do not insist immediately that you do anything you are required to do under these
              terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will
              not prevent us taking steps against you at a later date.
            </li>
            <li><b>Which laws apply to these and where you may bring legal proceedings.</b> Any dispute or claim arising out of or in connection with these terms or their subject matter
              or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of England and the courts of
              England shall have exclusive jurisdiction to settle any such dispute or claim (which is a jurisdiction we have chosen due to the global nature of our business).
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions'
}
</script>

<style scoped>
  .anchorInline  {
    position: absolute;
    left: 0;
    top: -113px;
  }
  .anchor {
    position: relative;
  }
  #terms {
    text-align: left;
    margin-top: 1px;
    margin-bottom: 4rem;
  }
  #terms h1 {
    text-align: center;
  }
  #terms ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }
  #terms ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }
  #terms ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
  }
  #terms li ol > li {
    margin: 0.5rem 0 0.5rem 0;
  }
  #terms li ol > li:before {
    content: counters(item, ".") " ";
  }
</style>
